import React, { useMemo } from "react";
import "./invoice-template.style.scss";
import logoDark from "../../assets/logo-dark-BILLME.png";

// for date purpose
import moment from "moment";
import { adToBs } from "@sbmdkl/nepali-date-converter";
import {
	converToWord,
	isEmptyObject,
	nepaliDateMMMM,
	rupeeConverter,
} from "../../global/function";

// redux
import { useSelector } from "react-redux";

export default function InvoiceTemplate({
	selectedBill,
	showBillFooter,
	page,
	fontStyle,
	isCreditNote = false,
}) {
	const { preferenceDetails, companyDetails } = useSelector(
		(state) => state.companyReducer
	);
	const { loginDetails } = useSelector((state) => state.authUserReducer);
	const getBillDate = (date) => {
		const nepDate = adToBs(moment(date).format("YYYY-MM-DD"));
		const billNepDate = `${nepaliDateMMMM(nepDate).split(" ")[0]} ${
			nepaliDateMMMM(nepDate).split(" ")[1]
		}, ${nepaliDateMMMM(nepDate).split(" ")[2]}`;
		const billEnDate = `${
			moment(date).format("DD") +
			" " +
			moment(date).format("MMMM") +
			", " +
			moment(date).format("YYYY")
		}`;
		return { billNepDate, billEnDate };
	};

	const TotalAmtInWords = useMemo(
		() => converToWord(selectedBill?.total_sales?.toFixed(2) || 0),
		[selectedBill?.total_sales]
	);

	const vatEnabled = useMemo(
		() => selectedBill?.vat > 0 || preferenceDetails?.vat,
		[selectedBill, preferenceDetails]
	);
	const serviceChargeEnabled = useMemo(
		() =>
			selectedBill?.taxable_service_charge > 0 ||
			selectedBill?.non_taxable_service_charge > 0 ||
			preferenceDetails?.service_charge > 0,
		[selectedBill, preferenceDetails]
	);

	function _renderInvoiceDate(invDate, label = "Invoice Date") {
		return (
			<div className="text-section">
				<div className="dark-text">
					<b>{label}:</b>
				</div>
				<div className="light-text text-col">
					{invDate && (
						<>
							<span>{getBillDate(invDate).billNepDate}</span>
							<span>{getBillDate(invDate).billEnDate}</span>
						</>
					)}
				</div>
			</div>
		);
	}

	function _renderBillFor(billForStr) {
		return (
			<div className="tags-wrapper" style={{ padding: 0 }}>
				{billForStr
					.split("#")
					.filter(Boolean)
					.map((tag, index) => (
						<div className="tag-container" key={index}>
							<span className="tag-item">{tag}</span>
						</div>
					))}
			</div>
		);
	}

	return (
		<div className="template-wrapper" style={fontStyle}>
			<div className="template-container">
				<div className="template-header-container">
					<div className="header-row-wrapper">
						<div className="header-row-item">
							<div className="brand-logo-container">
								<img
									crossOrigin="anonymous"
									src={
										selectedBill?.company?.company_logo ||
										logoDark
									}
									alt="logo"
									className="brand-image"
								/>
								<div className="brand-text-container">
									<div
										className="brand-text brand-title"
										style={{
											color: selectedBill?.company
												?.company_color_code,
										}}
									>
										{selectedBill?.company?.company_name ||
											"Company Name"}
									</div>
									{!isEmptyObject(selectedBill?.company) && (
										<div className="brand-text">
											<b>VAT / PAN No.: </b>
											{
												companyDetails?.identification
													?.registration_number
											}
										</div>
									)}
								</div>
							</div>
						</div>
						<div className="header-row-item">
							{isCreditNote ? (
								<div className="invoice-container">
									<div className="invoice-title credit">
										Credit Note
									</div>
									<div className="invoice-text-container">
										<div className="text-section">
											<div className="dark-text">
												<b>Credit Note #:</b>
											</div>
											<div className="light-text">
												{
													selectedBill?.credit_note_number
												}
											</div>
										</div>

										<div className="text-section">
											<div className="dark-text">
												<b>Reference Invoice #:</b>
											</div>
											<div className="light-text">
												{
													selectedBill?.ref_invoice_number
												}
											</div>
										</div>
										<div className="text-section">
											<div className="dark-text">
												<b>Credit Note Date:</b>
											</div>
											<div className="light-text text-col">
												{selectedBill?.credit_note_date && (
													<>
														<span>
															{
																getBillDate(
																	selectedBill?.credit_note_date
																).billNepDate
															}
														</span>
														<span>
															{
																getBillDate(
																	selectedBill?.credit_note_date
																).billEnDate
															}
														</span>
													</>
												)}
											</div>
										</div>
										<div className="text-section">
											<div className="dark-text">
												<b>Page:</b>
											</div>
											<div className="light-text">
												{page}
											</div>
										</div>
									</div>
								</div>
							) : (
								<div className="invoice-container">
									{selectedBill?.draft_id ? (
										<div className="invoice-title draft">
											Quotation
										</div>
									) : (
										<div className="invoice-title bill">
											{selectedBill?.print_copies > 0
												? "Invoice"
												: "Tax Invoice"}
										</div>
									)}

									<div className="invoice-text-container">
										<div className="text-section">
											<div className="dark-text">
												<b>Invoice #:</b>
											</div>
											<div className="light-text">
												{selectedBill?.invoice_number}
											</div>
										</div>
										{selectedBill?.draft_id ? (
											<>
												{_renderInvoiceDate(
													moment().format(
														"YYYY-MM-DD"
													)
												)}
												{_renderInvoiceDate(
													selectedBill?.invoice_date,
													"Sales Date"
												)}
											</>
										) : (
											_renderInvoiceDate(
												selectedBill?.invoice_date
											)
										)}

										{/* <div className="text-section">
									<div className="dark-text">
										<b>Due Date:</b>
									</div>
									<div className="light-text">
										10 July 2023
									</div>
								</div> */}
										<div className="text-section">
											<div className="dark-text">
												<b>Page:</b>
											</div>
											<div className="light-text">
												{page}
											</div>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
					<div className="header-col-wrapper">
						<div className="header-row-wrapper">
							<div className="bill-container">
								<div className="text-container">
									<h5 className="section-title">
										Invoice To:
									</h5>
									<div className="light-text capitalize">
										{selectedBill?.buyer_name}
									</div>
									{/* {selectedBill?.customer_mobile && (
									<div className="light-text">
										<i className="fa-solid fa-phone"></i>
										&nbsp;
										{selectedBill?.customer_mobile}
									</div>
								)}
								{selectedBill?.customer_email && (
									<div className="light-text lowercase">
										<i className="fa-solid fa-envelope"></i>
										&nbsp;
										{selectedBill?.customer_email}
									</div>
								)} */}
								</div>

								{selectedBill?.buyer_pan && (
									<div className="text-section">
										<div className="dark-text">
											Customer VAT/PAN:
										</div>
										<div className="light-text">
											{selectedBill?.buyer_pan}
										</div>
									</div>
								)}
							</div>
							<div className="bill-container">
								<div className="text-container">
									<h5 className="section-title">
										Issued By:
									</h5>
									<div className="light-text capitalize">
										{/* {selectedBill?.user &&
										`${selectedBill?.user?.first_name} ${selectedBill?.user?.last_name}`} */}
										User ID #{" "}
										{selectedBill?.user &&
											selectedBill?.user?.user_id}
									</div>
									{/* {selectedBill?.user?.user_email && (
									<div className="light-text lowercase">
										<i className="fa-solid fa-envelope"></i>
										&nbsp;
										{selectedBill?.user?.user_email}
									</div>
								)} */}
								</div>
							</div>
							{selectedBill?.print_copies > 0 && (
								<div className="bill-container">
									<div className="bill-flag-container">
										<div
											className="bill-flag"
											style={{
												backgroundColor:
													selectedBill?.company
														?.company_color_code,
											}}
										>
											Copy of Original &nbsp; [{" "}
											{selectedBill?.print_copies} ]
										</div>
									</div>
								</div>
							)}
						</div>
						{selectedBill?.bill_for && (
							<div className="header-text-section">
								<div className="dark-text">Bill For:</div>
								{_renderBillFor(selectedBill?.bill_for)}
							</div>
						)}
					</div>
				</div>

				<div className="template-body-container">
					<div
						className={`table-grid-container highlighted ${
							vatEnabled ? "five-col" : ""
						}`}
					>
						{vatEnabled && (
							<div className="table-item-text">
								<b>HS Code</b>
							</div>
						)}
						<div className="table-item-text">
							<b>Item Description</b>
						</div>
						<div className="table-item-text align-right">
							<b>Qty.</b>
						</div>
						<div className="table-item-text align-right">
							<b>Price</b>
						</div>
						<div className="table-item-text align-right">
							<b>Amount</b>
						</div>
					</div>
					{selectedBill?.sliced_items?.length > 0 ? (
						selectedBill?.sliced_items?.map((item, i) => (
							<div
								key={i}
								className={`table-grid-container ${
									vatEnabled ? "five-col" : ""
								}`}
							>
								{vatEnabled && (
									<div className="table-item-text">
										{item?.hs_code}
									</div>
								)}
								<div className="table-item-text capitalize">
									{item.item_name}
								</div>
								<div className="table-item-text align-right">
									{item.item_qty}
								</div>
								<div className="table-item-text align-right">
									{item.item_price}
								</div>
								<div className="table-item-text align-right">
									{item.total_price}
								</div>
							</div>
						))
					) : (
						<div style={{ minHeight: "25px" }} />
					)}
					{showBillFooter ? (
						<div className="table-footer-container">
							<div className="span-grid-item">
								<div style={{ flex: 1 }}>
									<div
										className="span-text highlighted"
										style={{
											color: selectedBill?.company
												?.company_color_code,
										}}
									>
										<b>
											{isCreditNote
												? "Reason for return"
												: "Additional Notes"}
										</b>
									</div>
									<div className="span-text ellipsis-text">
										{isCreditNote
											? selectedBill?.reason_for_return
											: selectedBill?.sale_info}
									</div>
								</div>
								<div className="payment-method-indicator">
									<div
										className="span-text highlighted"
										style={{
											color: selectedBill?.company
												?.company_color_code,
										}}
									>
										<b>Payment Method</b>
									</div>
									<div className="span-text capitalize">
										{selectedBill?.payment_mode}
									</div>
								</div>
							</div>
							<div className="table-item-text">
								<b>SubTotal</b>
							</div>
							<div className="table-item-text align-right">
								Rs. {rupeeConverter(selectedBill?.total_sales)}
							</div>
							<div className="table-item-text">
								<b>Discount</b>
							</div>
							<div className="table-item-text align-right">
								Rs.{" "}
								{rupeeConverter(
									(selectedBill?.taxable_discount || 0) +
										(selectedBill?.non_taxable_discount ||
											0)
								)}
							</div>
							{serviceChargeEnabled && (
								<>
									<div className="table-item-text">
										<b>Service Charge</b>
									</div>
									<div className="table-item-text align-right">
										Rs.{" "}
										{rupeeConverter(
											(selectedBill?.taxable_service_charge ||
												0) +
												(selectedBill?.non_taxable_service_charge ||
													0)
										)}
									</div>
								</>
							)}
							<div className="table-item-text">
								<b>Non-Taxable Amount</b>
							</div>
							<div className="table-item-text align-right">
								Rs.{" "}
								{rupeeConverter(
									selectedBill?.non_taxable_amt || 0
								)}
							</div>
							<div className="table-item-text">
								<b>Taxable Amount</b>
							</div>
							<div className="table-item-text align-right">
								Rs.{" "}
								{rupeeConverter(
									selectedBill?.taxable_sales_vat || 0
								)}
							</div>
							{vatEnabled && (
								<>
									<div className="table-item-text">
										<b>VAT @ 13%</b>
									</div>
									<div className="table-item-text align-right">
										Rs.{" "}
										{rupeeConverter(selectedBill?.vat || 0)}
									</div>
								</>
							)}
							<div
								className="table-item-text highlighted"
								style={{
									backgroundColor:
										selectedBill?.company
											?.company_color_code,
								}}
							>
								<b>Grand Total</b>
							</div>
							<div
								className="table-item-text highlighted align-right"
								style={{
									backgroundColor:
										selectedBill?.company
											?.company_color_code,
								}}
							>
								Rs. {rupeeConverter(selectedBill?.grand_total)}
							</div>
						</div>
					) : null}

					<div
						style={{
							borderBottom: "1px dashed #9D9D9D",
						}}
					/>
					{!showBillFooter ? (
						<div
							className="amount-item-indicator"
							style={{ justifyContent: "flex-end" }}
						>
							Number of Items:{" "}
							{selectedBill?.sliced_items?.length} out of{" "}
							{selectedBill?.billed_items?.length}
						</div>
					) : (
						<div className="amount-item-indicator">
							<span style={{ flex: 3, fontSize: "0.9em" }}>
								<b>Amount in words: </b>
								{TotalAmtInWords}
							</span>
							<span style={{ flex: 1, textAlign: "right" }}>
								Total Items:{" "}
								{selectedBill?.billed_items?.length}
							</span>
						</div>
					)}
				</div>

				<div className="template-footer-container">
					{isCreditNote || selectedBill?.bill_void ? (
						<div className="template-footer-row">
							<div className="footer-item">
								<div className="print-details">
									Current Date: {moment().format("llll")} |
									Employee: {loginDetails?.first_name}{" "}
									{loginDetails?.last_name}
								</div>
							</div>
						</div>
					) : (
						<div className="template-footer-row">
							<div className="footer-item">
								{showBillFooter && (
									<div className="footer-text">
										Thank you for your kind cooperation!
									</div>
								)}
								<div className="print-details">
									Current Date: {moment().format("llll")} |
									Employee: {loginDetails?.first_name}{" "}
									{loginDetails?.last_name}
								</div>
								{showBillFooter && (
									<>
										{preferenceDetails?.terms_condition &&
											(selectedBill?.draft_id ||
												selectedBill?.print_copies ===
													0) && (
												<div className="footer-tnc">
													<b
														style={{
															color: selectedBill
																?.company
																?.company_color_code,
														}}
													>
														Terms & Conditions
													</b>
													<div>
														<div
															className="tnc-data"
															dangerouslySetInnerHTML={{
																__html: preferenceDetails?.terms_condition,
															}}
														/>
													</div>
												</div>
											)}
									</>
								)}
							</div>

							{(selectedBill?.draft_id ||
								selectedBill?.print_copies === 0) && (
								<div className="footer-item flex-end">
									<div className="sign-space"></div>
									<div className="footer-text">
										Checked By
									</div>
								</div>
							)}
						</div>
					)}
					<div className="footer-divider"></div>
					<div className="brand-details-container">
						<div className="details-row">
							<div className="details-text">
								<b>Contact:</b>{" "}
								{selectedBill?.company?.company_phone &&
									`${selectedBill?.company?.company_phone} | `}
								{selectedBill?.company?.company_mobile}
							</div>
							<div className="details-text">
								<b>E-mail:</b>{" "}
								{selectedBill?.company?.company_email}
							</div>
						</div>
						<div className="details-row">
							<div className="details-text capitalize">
								<b>Address:</b>{" "}
								{selectedBill?.company?.company_address &&
									`${selectedBill?.company?.company_address},`}{" "}
								{selectedBill?.company?.company_city &&
									`${selectedBill?.company?.company_city},`}{" "}
								{selectedBill?.company?.company_country}
							</div>
							{selectedBill?.company?.company_website && (
								<div className="details-text">
									<b>Web:</b>{" "}
									{selectedBill?.company?.company_website}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			{selectedBill?.bill_void ? (
				<div className="template-status void">void</div>
			) : null}
		</div>
	);
}
